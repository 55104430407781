import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="Section">
      <div className="PanelContainer">
        <h1 className="Title">
          <span role="img" aria-label="magnifier">
            🔎
          </span>{" "}
          404: Not Found
        </h1>
        <p className="Lead">
          You just hit a route that doesn&#39;t exist... the sadness.
        </p>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
